import Vue from 'vue'
import VueWorker from 'vue-worker'
import VJsoneditor from 'v-jsoneditor'
import VueToast from 'vue-toast-notification'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueCompositionAPI from '@vue/composition-api'
import {
  AlertPlugin,
  BootstrapVue,
  BootstrapVueIcons,
  IconsPlugin,
  ToastPlugin
} from 'bootstrap-vue'

export function registerPlugins() {
  Vue.use(VueWorker)
  Vue.use(CKEditor)
  Vue.use(VJsoneditor)
  Vue.use(VueCompositionAPI)
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  Vue.use(IconsPlugin)
  Vue.use(ToastPlugin)
  Vue.use(AlertPlugin)
  Vue.use(VueToast)
}
