import i18n from '@/i18n'
import Empty from '@/app/shared/views/empty'

const adminRoutes = [
  {
    children: [
      {
        component: () =>
          import('@/app/dashboard/views/admin/project/project-list'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.project'),
              to: { name: 'project-list' }
            },
            {
              text: `${i18n.t('dashboard.term.project')} ${i18n.t('dashboard.term.list')}`,
              to: { name: 'project-list' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'project-list',
        path: ''
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/project/project-create'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.project'),
              to: { name: 'project-list' }
            },
            {
              text: `${i18n.t('dashboard.term.project')} ${i18n.t('dashboard.term.list')}`,
              to: { name: 'project-list' }
            },
            {
              text: `${i18n.t('dashboard.term.createProject')}`,
              to: { name: 'project-create' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'project-create',
        path: 'create'
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/project/project-create'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.project'),
              to: { name: 'project-list' }
            },
            {
              text: i18n.t('dashboard.term.projects'),
              to: { name: 'project-list' }
            },
            {
              text: i18n.t('dashboard.term.modifyProject'),
              to: { name: 'project-modify' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'project-modify',
        path: ':id/modify'
      },
      {
        children: [
          // {
          //   path: 'overview',
          //   name: 'project-detail-overview',
          //   meta: { requiresAuth: true, requiresTenant: true },
          //   component: () => import('@/app/dashboard/views/admin/project/project-detail-overview')
          // },
          {
            children: [
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-dataset-label'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dataset'),
                      to: { name: 'project-detail-dataset' }
                    },
                    {
                      text: i18n.t('dashboard.term.label'),
                      to: { name: 'project-detail-dataset-label' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-dataset-label',
                path: 'label'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-dataset-assignment'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dataset'),
                      to: { name: 'project-detail-dataset' }
                    },
                    {
                      text: i18n.t('dashboard.term.assignment_work'),
                      to: { name: 'project-detail-dataset-assignment' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-dataset-assignment',
                path: 'assignment'
              }
            ],
            components: {
              default: () =>
                import(
                  '@/app/dashboard/views/admin/project/project-detail-dataset'
                ),
              nav: () =>
                import(
                  '@/app/dashboard/views/admin/project/partials/navigation-dataset'
                )
            },
            name: 'project-detail-dataset',
            path: 'dataset',
            redirect: { name: 'project-detail-dataset-label' }
          },
          {
            component: () =>
              import(
                '@/app/dashboard/views/admin/project/project-detail-workshop-list'
              ),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.workshop'),
                  to: { name: 'project-detail-dataset' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'project-detail-workshop-list',
            path: 'workshops'
          },
          {
            component: () =>
              import(
                '@/app/dashboard/views/admin/project/project-detail-workshop-detail'
              ),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.workshopDetail'),
                  to: { name: 'project-detail-workshop-detail' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'project-detail-workshop-detail',
            path: 'workshops/:workshop_id'
          },
          {
            component: () =>
              import('@/app/dashboard/views/admin/project/project-detail-tags'),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.tagsManagement'),
                  to: { name: 'project-detail-tags' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'project-detail-tags',
            path: 'tags'
          },
          {
            component: () =>
              import(
                '@/app/dashboard/views/admin/project/project-detail-guide-list'
              ),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.guideline'),
                  to: { name: 'project-detail-guide-list' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'project-detail-guide-list',
            path: 'guides'
          },
          {
            component: () =>
              import(
                '@/app/dashboard/views/admin/project/project-detail-guide-detail'
              ),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.modifyGuideline'),
                  to: { name: 'project-detail-guide-detail' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'project-detail-guide-detail',
            path: 'guides/:guide_id'
          },
          {
            children: [
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-quality-assurance-reviewers'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.qualityManagement'),
                      to: { name: 'project-detail-quality-assurance' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewerManagement'),
                      to: {
                        name: 'project-detail-quality-assurance-reviewers'
                      }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-quality-assurance-reviewers',
                path: 'reviewers'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-quality-assurance-stages'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.qualityManagement'),
                      to: { name: 'project-detail-quality-assurance' }
                    },
                    {
                      text: i18n.t('dashboard.term.stageManagement'),
                      to: { name: 'project-detail-quality-assurance-stages' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-quality-assurance-stages',
                path: 'stages'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-quality-assurance-tags'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.qualityManagement'),
                      to: { name: 'project-detail-quality-assurance' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewTagManagement'),
                      to: { name: 'project-detail-quality-assurance-tags' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-quality-assurance-tags',
                path: 'tags'
              }
            ],
            components: {
              default: () =>
                import(
                  '@/app/dashboard/views/admin/project/project-detail-quality-assurance'
                ),
              nav: () =>
                import(
                  '@/app/dashboard/views/admin/project/partials/navigation-quality-assurance'
                )
            },
            name: 'project-detail-quality-assurance',
            path: 'quality-assurance',
            redirect: { name: 'project-detail-quality-assurance-reviewers' }
          },
          {
            children: [
              // {
              //   path: 'dataset',
              //   name: 'project-detail-statistics-dataset',
              //   meta: { requiresAuth: true, requiresTenant: true },
              //   component: () => import('@/app/dashboard/views/admin/project/project-detail-statistics-dataset')
              // },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-statistics-labeler'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.statistics'),
                      to: { name: 'project-detail-statistics' }
                    },
                    {
                      text: i18n.t('dashboard.term.labelerStatistics'),
                      to: { name: 'project-detail-statistics-labeler' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-statistics-labeler',
                path: 'labeler'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-statistics-reviewer'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.statistics'),
                      to: { name: 'project-detail-statistics' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewerStatistics'),
                      to: { name: 'project-detail-statistics-labeler' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-statistics-reviewer',
                path: 'reviewer'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/project/project-detail-statistics-classification.vue'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.project'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.statistics'),
                      to: { name: 'project-detail-statistics' }
                    },
                    {
                      text: '클래스 통계',
                      to: { name: 'project-detail-statistics-classification' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'project-detail-statistics-classification',
                path: 'classification'
              }
            ],
            components: {
              default: () =>
                import(
                  '@/app/dashboard/views/admin/project/project-detail-statistics'
                ),
              nav: () =>
                import(
                  '@/app/dashboard/views/admin/project/partials/navigation-statistics'
                )
            },
            name: 'project-detail-statistics',
            path: 'statistics',
            redirect: { name: 'project-detail-statistics-labeler' }
          },
          {
            component: () =>
              import(
                '@/app/dashboard/views/admin/project/project-detail-settings'
              ),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.project'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.projects'),
                  to: { name: 'project-list' }
                },
                {
                  text: i18n.t('dashboard.term.preferences'),
                  to: { name: 'project-detail-settings' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'project-detail-settings',
            path: 'settings'
          }
        ],
        component: () =>
          import('@/app/dashboard/views/admin/project/project-detail'),
        name: 'project-detail',
        path: ':id',
        redirect: { name: 'project-detail-dataset-label' }
      }
    ],
    component: Empty,
    path: 'projects'
  },
  {
    children: [
      {
        children: [
          {
            component: () =>
              import('@/app/dashboard/views/admin/neural_net/neural-net-list'),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.ml'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.neuralNetworkDetail'),
                  to: { name: 'neural-net-list' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'neural-net-list',
            path: 'neural_net'
          },
          {
            children: [
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/neural_net/neural-net-detail-overview'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkOverview'),
                      to: { name: 'neural-net-detail-overview' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'neural-net-detail-overview',
                path: 'overview'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/neural_net/neural-net-detail-train'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkTrain'),
                      to: { name: 'neural-net-detail-train' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'neural-net-detail-train',
                path: 'overview/train'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/neural_net/neural-net-detail-model-list'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.models'),
                      to: { name: 'neural-net-detail-model-list' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'neural-net-detail-model-list',
                path: 'model'
              },
              {
                component: () =>
                  import(
                    '@/app/dashboard/views/admin/neural_net/neural-net-detail-model-detail'
                  ),
                meta: {
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.ml'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.neuralNetworkDetail'),
                      to: { name: 'neural-net-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.models'),
                      to: { name: 'neural-net-detail-model-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.modelDetail'),
                      to: { name: 'neural-net-detail-model-detail' }
                    }
                  ],
                  requiresAuth: true,
                  requiresTenant: true
                },
                name: 'neural-net-detail-model-detail',
                path: 'model/:model_code'
              }
            ],
            component: () =>
              import(
                '@/app/dashboard/views/admin/neural_net/neural-net-detail'
              ),
            name: 'neural-net-detail',
            path: 'neural_net/:neural_net_code',
            redirect: { name: 'neural-net-detail-overview' }
          },
          {
            component: () =>
              import('@/app/dashboard/views/admin/task/task-list'),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.ml'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.trainStatus'),
                  to: { name: 'task-list' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'task-list',
            path: 'tasks'
          },
          {
            component: () =>
              import('@/app/dashboard/views/admin/task/task-detail'),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.ml'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.neuralNetworkDetail'),
                  to: { name: 'neural-net-list' }
                },
                {
                  text: i18n.t('dashboard.term.trainStatusDetail'),
                  to: { name: 'task-detail' }
                }
              ],
              requiresAuth: true,
              requiresTenant: true
            },
            name: 'task-detail',
            path: 'tasks/:task_id'
          }
        ],
        component: () =>
          import('@/app/dashboard/views/admin/neural_net/neural-net-layout'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.ml'),
              to: { name: 'neural-net-list' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'neural-net-layout',
        path: ''
      }
    ],
    component: Empty,
    path: 'ml',
    redirect: { name: 'neural-net-list' }
  },
  {
    children: [
      {
        component: () =>
          import('@/app/dashboard/views/admin/dataset/dataset-list'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'dataset-list',
        path: ''
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/dataset/dataset-create'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.createDataset'),
              to: { name: 'dataset-create' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'dataset-create',
        path: 'create'
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/dataset/dataset-create'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.modifyDataset'),
              to: { name: 'dataset-create' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'dataset-modify',
        path: ':id/modify'
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/dataset/dataset-detail'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.dataset'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasets'),
              to: { name: 'dataset-list' }
            },
            {
              text: i18n.t('dashboard.term.datasetDetail'),
              to: { name: 'dataset-detail' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'dataset-detail',
        path: ':id'
      }
    ],
    component: Empty,
    path: 'datasets',
    redirect: { name: 'dataset-list' }
  },
  {
    children: [
      {
        component: () =>
          import('@/app/dashboard/views/admin/integration/agent-list'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'agent-list',
        path: 'agent'
      },
      {
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agentDetail'),
              to: { name: 'agent-detail' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'agent-detail',
        path: 'agent/:id',
        redirect: { name: 'agent-overview' }
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/integration/storage-list'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.storage'),
              to: { name: 'storage-list' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'storage-list',
        path: 'storage'
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/integration/agent-overview'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.overview'),
              to: { name: 'agent-overview' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'agent-overview',
        path: ':id/overview'
      },
      {
        component: () =>
          import('@/app/dashboard/views/admin/integration/agent-tasks'),
        meta: {
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.integration'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.agent'),
              to: { name: 'agent-list' }
            },
            {
              text: i18n.t('dashboard.term.activateWork'),
              // text: i18n.t('dashboard.term.overview'),
              to: { name: 'agent-tasks' }
            }
          ],
          requiresAuth: true,
          requiresTenant: true
        },
        name: 'agent-tasks',
        path: ':id/tasks'
      }
    ],
    component: () =>
      import('@/app/dashboard/views/admin/integration/integration-detail'),
    meta: {
      requiresAuth: true,
      requiresTenant: true
    },
    name: 'integration-detail',
    path: 'integrations',
    redirect: { name: 'agent-list' }
  },
  {
    children: [
      {
        children: [
          {
            component: () =>
              import('@/app/dashboard/views/admin/member/member-list'),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.member'),
                  to: { name: 'member-list' }
                },
                {
                  text: i18n.t('dashboard.term.memberList'),
                  to: { name: 'member-list' }
                }
              ],
              requiresAuth: true
            },
            name: 'member-list',
            path: 'list'
          }
        ],
        components: {
          default: () =>
            import('@/app/dashboard/views/admin/member/member-layout')
        },
        name: 'member-layout',
        path: ''
      }
    ],
    component: () =>
      import('@/app/dashboard/views/admin/member/member-settings'),
    name: 'member-settings',
    path: 'member',
    redirect: { name: 'list' }
  },
  {
    children: [
      {
        children: [
          {
            component: () =>
              import('@/app/dashboard/views/admin/setting/setting-account'),
            meta: {
              breadCrumb: [
                {
                  text: i18n.t('dashboard.term.settings'),
                  to: { name: 'settings' }
                },
                {
                  text: i18n.t('dashboard.term.accountSetting'),
                  to: { name: 'setting-user' }
                }
              ],
              requiresAuth: true
            },
            name: 'setting-account',
            path: 'account'
          }
        ],
        components: {
          default: () =>
            import('@/app/dashboard/views/admin/setting/setting-layout'),
          nav: () =>
            import(
              '@/app/dashboard/views/admin/setting/partials/navigation-setting'
            )
        },
        name: 'setting-layout',
        path: ''
      }
    ],
    component: () => import('@/app/dashboard/views/admin/setting/setting'),
    path: 'settings',
    redirect: { name: 'profile' }
  }
]

export default adminRoutes
