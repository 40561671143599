import authRoutes from '@/app/dashboard/routes/auth'
import adminRoutes from '@/app/dashboard/routes/admin'
import pagesRoutes from '@/app/dashboard/routes/pages'
import labelerRoutes from '@/app/dashboard/routes/labeler'
import AuthLayout from '@/app/dashboard/layouts/auth-layout'
import BaseLayout from '@/app/dashboard/layouts/base-layout'
import reviewerRoutes from '@/app/dashboard/routes/reviewer'
import PagesLayout from '@/app/dashboard/layouts/pages-layout'
import DashboardLayout from '@/app/dashboard/layouts/dashboard-layout'

const dashboardRoutes = [
  {
    children: [
      {
        children: [...adminRoutes],
        component: DashboardLayout,
        path: 'admin'
      },
      {
        children: [...labelerRoutes],
        component: DashboardLayout,
        path: 'labeler'
      },
      {
        children: [...reviewerRoutes],
        component: DashboardLayout,
        path: 'reviewer'
      },
      {
        children: [...authRoutes],
        component: AuthLayout,
        path: 'auth'
      },
      {
        children: [...pagesRoutes],
        component: PagesLayout,
        path: 'pages'
      }
    ],
    component: BaseLayout,
    path: '',
    redirect: { name: 'dashboard-project-list' }
  }
]

export default dashboardRoutes
