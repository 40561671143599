import api from '@/app/shared/axios/api'

export default class UserService {
  static async addTags(data) {
    await api.patch('users/add_tags/', data)
  }

  static async me() {
    const response = await api.get('users/me/')

    return response.data
  }

  static async get(id) {
    const response = await api.get(`users/${id}/`)

    return response.data
  }

  static async create(userData) {
    const response = await api.post('users/', userData)

    return response.data
  }

  static async list(params = {}) {
    const response = await api.get('users/', { params })

    return response.data
  }

  static async updateMe(userData) {
    const response = await api.put('users/me/', userData)

    return response.data
  }

  static async changeEmail(data) {
    const response = await api.put('users/change_email/', data)

    return response.data
  }

  static async updatePassword(data) {
    const response = await api.post('users/set_password/', data)

    return response.data
  }

  static async resetEmail(email) {
    const response = await api.post('users/reset_email/', { email })

    return response.data
  }

  static async delete(form) {
    const response = await api.delete('users/me/', {
      data: form
    })

    return response.data
  }

  static async userVerified(userData) {
    const response = await api.post('user_verifications/', userData)

    return response.data
  }

  static async emailVerifyCreate(email) {
    const response = await api.post('users/email_verify/', { email })

    return response.data
  }

  static async resetEmailConfirm(data) {
    const response = await api.post('users/reset_email_confirm/', data)

    return response.data
  }

  static async createVerified(userData) {
    const response = await api.post('users/create_verified/', userData)

    return response.data
  }

  static async resetPasswordConfirm(data) {
    const response = await api.post('users/reset_password_confirm/', data)

    return response.data
  }

  static async resetPassword(email) {
    const response = await api.post('users/reset_password/', {
      email,
      site: 'synapse'
    })

    return response.data
  }
}
