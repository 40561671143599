import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from '@/app/shared/auth/auth'
import annotatorRoutes from '@/app/annotator/routes'
import dashboardRoutes from '@/app/dashboard/routes'
import UserService from '@/app/dashboard/services/user-service'

Vue.use(VueRouter)

const routes = [
  ...dashboardRoutes,
  ...annotatorRoutes,
  {
    component: () => import('@/app/dashboard/views/auth/error'),
    name: 'Error',
    path: '/auth/error'
  },
  {
    component: () => import('@/app/dashboard/views/auth/authentication'),
    name: 'authentication',
    path: '/authentication'
  },
  {
    component: () => import('@/app/dashboard/views/auth/adblock-alert'),
    name: 'adblock-alert',
    path: '/adblock_alert'
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.length === 0) {
    next({ name: 'login' })

    return
  }

  if (to.name === 'login') {
    next()

    return
  }

  try {
    let user = null
    if (Auth.isLoggedIn()) {
      user = await UserService.me()
      // Save user to local storage
      localStorage.setItem('user', JSON.stringify(user))
    }

    if (to.meta.requiresAuth && !Auth.isLoggedIn()) {
      next({ name: 'login' })
    } else if (to.meta.requiresTenant && !Auth.isTenantIn()) {
      next({ name: 'synapse-project-list' })
    } else {
      next()
    }
  } catch (error) {
    console.error('Router guard error:', error)
    if (to.name !== 'login') {
      next({ name: 'login' })
    } else {
      next()
    }
  }
})

export default router
