import { AuthToken } from '@/app/shared/auth/auth-token'
import { TenantToken } from '@/app/shared/auth/tenant-token'

import {
  DEFAULT_LANGUAGE,
  HEADER_KEYS,
  STORAGE_KEYS,
  TOKEN_PREFIX
} from './api-config'

export function setHeaders(options) {
  const userDelegator = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.USER_DELEGATOR)
  )
  const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER))
  const authToken = AuthToken.get()
  const tenantToken = TenantToken.get()
  const language =
    localStorage.getItem(STORAGE_KEYS.LOCALE) ||
    process.env.VUE_APP_I18N_LOCALE ||
    DEFAULT_LANGUAGE

  options.headers[HEADER_KEYS.ACCEPT_LANGUAGE] = language
  if (userDelegator) options.headers[HEADER_KEYS.SYNAPSE_LOGIN_AS] = user.id
  if (authToken)
    options.headers[HEADER_KEYS.AUTHORIZATION] = `${TOKEN_PREFIX} ${authToken}`
  if (tenantToken)
    options.headers[HEADER_KEYS.SYNAPSE_TENANT] =
      `${TOKEN_PREFIX} ${tenantToken}`
}
