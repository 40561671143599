import Vue from 'vue'
import App from '@/app/app'
import store from '@/app/app-store'
import router from '@/app/app-routes'
import { useLog } from '@/app/core/lib/util'
import 'bootstrap'
import { initClipboard } from '@/app/shared/utils/Clipboard'
import 'vue-toast-notification/dist/theme-sugar.css'

import i18n from './i18n'
import { registerPlugins } from './plugins'
import { initSentry } from './plugins/sentry'
import { registerComponents } from './plugins/components'
import { registerFormulatePlugin } from './plugins/formulate'

Vue.config.productionTip = false

Vue.prototype.$cvready = {
  addCb(fn) {
    this.cbs.push(fn)
  },
  cbs: []
}

registerPlugins()
registerComponents()
registerFormulatePlugin()
initSentry()

new Vue({
  i18n,
  render: (h) => h(App),
  router,
  store
}).$mount('#app')

const { log } = useLog()

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/cache-worker.js')
      .then(() => {
        log('Cache worker installed.')
      })
      .catch((registrationError) => {
        log('SW registration failed: ', registrationError)
      })
    initClipboard()
  })
}
