import api from '@/app/shared/axios/api'
import { TenantToken } from '@/app/shared/auth/tenant-token'

export default class TenantService {
  static async list() {
    const response = await api.get('tenants/')

    return response.data
  }

  static async create(name) {
    TenantToken.set('', false)
    const response = await api.post('tenants/', { name })

    return response.data
  }

  static async get() {
    const tenantToken = TenantToken.get()
    const response = await api.get(`tenants/${tenantToken}`)
    const data = response.data

    return data
  }
}
