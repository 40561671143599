import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { browserTracingIntegration } from '@sentry/browser'

export function initSentry() {
  Sentry.init({
    dsn: 'https://fd7cfc5c8128be23d4fe4dabf385f169@sentry.datamaker.io/3',
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.1,
    Vue
  })
}
