export const STORAGE_KEYS = {
  LOCALE: 'locale',
  USER: 'user',
  USER_DELEGATOR: 'userDelegator'
}

export const HEADER_KEYS = {
  ACCEPT_LANGUAGE: 'Accept-Language',
  AUTHORIZATION: 'Authorization',
  SYNAPSE_LOGIN_AS: 'Synapse-Login-As',
  SYNAPSE_TENANT: 'Synapse-tenant'
}

export const TOKEN_PREFIX = 'Token'
export const DEFAULT_LANGUAGE = 'en'
