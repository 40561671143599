export default class DataUnitUtil {
  static getDataUnitFromAssignment(assignment) {
    if (!assignment) return null

    return assignment?.task?.data_unit || null
  }
  static getMetaFromAssignment(assignment) {
    if (!assignment) return null

    return assignment?.task?.data_unit?.meta || null
  }
  static getFilesFromAssignment(assignment) {
    if (!assignment) return null

    return assignment?.task?.data_unit?.files || null
  }

  static getDataUnitIdFromAssignment(assignment) {
    if (!assignment) return null

    return assignment?.task?.data_unit?.id || null
  }

  static getMetaValueFromAssignment(assignment, key) {
    if (!assignment) return null

    return assignment?.task?.data_unit?.meta?.[key] || null
  }

  static getPrimaryFileFromAssignment(assignment) {
    const key = DataUnitUtil.getPrimaryFileKeyFromAssignment(assignment)
    if (!key) return null

    return DataUnitUtil.getFilesFromAssignment(assignment)[key] || null
  }

  static getPrimaryFileKeyFromAssignment(assignment) {
    if (!assignment) return null

    const files = DataUnitUtil.getFilesFromAssignment(assignment)
    if (!files) return null

    const keys = Object.keys(label.data_unit.files)
    let type = null
    for (const key of keys) {
      if (files[key]?.is_primary) {
        type = key
        break
      }
    }

    return type
  }
}
