import { useErrorStore } from '@/app/shared/store/error'

const { store } = useErrorStore()

export function handleBadRequest(error) {
  if (error.response.data.non_field_errors) {
    return { contents: error.response.data.non_field_errors.join(), show: 3 }
  }
  if (Array.isArray(error.response.data.code)) {
    return { contents: error.response.data.code.join(), show: 3 }
  }
  if (error.response.data.file_specifications?.non_field_errors) {
    return {
      contents: error.response.data.file_specifications.non_field_errors.join(),
      show: 3
    }
  }
  if (error.response.data.verification?.non_field_errors) {
    return {
      contents: error.response.data.verification.non_field_errors.join(),
      show: 3
    }
  }

  return Object.entries(error.response.data || {}).map(([key, value]) => ({
    contents: value,
    field: key,
    show: 3
  }))
}

export function handleResponseError(error) {
  if (!error.response) return Promise.reject(error)

  switch (error.response.status) {
    case 400:
      error.response.errors = handleBadRequest(error)
      break
    case 401:
      location.href = process.env.VUE_APP_LOGIN_REDIRECT_URL
      break
    case 403:
      if (error.response.data.code === 'not_verified') {
        // FIXME 시냅스에서 승인대기 페이지가 개발되면 이동시켜야 함
        // location.href =
        //   '/auth/user_waiting_approval?redirect=' +
        //   encodeURIComponent(window.location.pathname + window.location.search)
        // location.href = process.env.VUE_APP_USER_WAITING_APPROVAL_REDIRECT_URL
        // 지금은 임시로 시냅스 프로젝트 리스트 페이지로 이동시킴
        location.href = process.env.VUE_APP_LOGIN_REDIRECT_URL
      }
      break
    case 500:
      store.dispatch('changeErrorState')
      break
  }
  error.response.inputErrors = error.response.data

  return Promise.reject(error)
}
