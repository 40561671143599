import axios from 'axios'

import { setHeaders } from './api-headers'
import { handleResponseError } from './api-error-handlers'

function handleGetParams(params) {
  const urlParams = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (!value) return
    if (Array.isArray(value) && value.length) {
      value.forEach((v) => urlParams.append(key, v))
    } else {
      urlParams.append(key, value)
    }
  })

  return urlParams
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

api.interceptors.request.use(
  async (options) => {
    setHeaders(options)
    if (options.method === 'get' && options.params) {
      options.params = handleGetParams(options.params)
    }

    return options
  },
  (error) => Promise.reject(error)
)

api.interceptors.response.use((options) => options, handleResponseError)

export default api
