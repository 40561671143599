const authRoutes = [
  {
    beforeEnter: (to, from, next) => {
      const redirectUrl = process.env.VUE_APP_LOGIN_REDIRECT_URL
      window.location.href = redirectUrl
    },
    name: 'login',
    path: 'login'
  },
  {
    beforeEnter: (to, from, next) => {
      const redirectUrl = process.env.VUE_APP_LOGIN_REDIRECT_URL
      window.location.href = redirectUrl
    },
    name: 'synapse-project-list',
    path: 'synapse-project-list'
  }
]

export default authRoutes
