export const formulateConfig = {
  classes: {
    errors: 'list-unstyled text-danger',
    help: 'form-text text-muted',
    input: 'form-control',
    inputHasErrors: 'is-invalid',
    outer: 'form-group'
  },
  library: {
    ajax_multi_select: {
      classification: 'text',
      component: 'AjaxMultiSelect',
      slotProps: {
        label: [
          'endpoint',
          'optionValue',
          'optionLabel',
          'computedOption',
          'searchOnFocus',
          'isMultiple',
          'searchOptions'
        ]
      }
    },
    ajax_select: {
      classification: 'text',
      component: 'AjaxSelect',
      slotProps: {
        label: [
          'extraOptions',
          'endpoint',
          'optionValue',
          'optionLabel',
          'computedOption',
          'searchOnFocus',
          'searchable',
          'placeholder'
        ]
      }
    },
    allocation_input: {
      classification: 'text',
      component: 'AllocationInput'
    },
    ck_editor_input: {
      classification: 'text',
      component: 'CkEditorInput'
    },
    classification_input: {
      classification: 'text',
      component: 'ClassificationInput',
      slotProps: {
        label: ['category', 'annotationType', 'schemaType']
      }
    },
    custom_base64_file_input: {
      classification: 'text',
      component: 'CustomBase64FileInput'
    },
    custom_dataset_input: {
      classification: 'text',
      component: 'CustomDatasetInput'
    },
    custom_date_input: {
      classification: 'text',
      component: 'CustomDateInput',
      slotProps: {
        help: ['helpText']
      }
    },
    custom_file_input: {
      classification: 'text',
      component: 'CustomFileInput',
      slotProps: {
        label: ['formFile', 'accept', 'deleteFile']
      }
    },
    custom_help_input: {
      classification: 'text',
      component: 'CustomHelpInput',
      slotProps: {
        help: ['helpText']
      }
    },
    custom_label_input: {
      classification: 'text',
      component: 'CustomLabelInput',
      slotProps: {
        label: ['labelText']
      }
    },
    custom_normal_input: {
      classification: 'text',
      component: 'CustomNormalInput',
      slotProps: {
        help: ['helpText']
      }
    },
    custom_option_select: {
      classification: 'text',
      component: 'CustomOptionSelect',
      slotProps: {
        label: ['optionType']
      }
    },
    email_verification_input: {
      classification: 'text',
      component: 'EmailVerificationInput'
    },
    iamport_input: {
      classification: 'text',
      component: 'IamportInput',
      slotProps: {
        label: ['form']
      }
    },
    json_editor_input: {
      classification: 'text',
      component: 'JsonEditorInput'
    },
    json_schema_input: {
      classification: 'text',
      component: 'JsonSchemaInput',
      slotProps: {
        label: ['schema', 'annotationType', 'usedBy']
      }
    },
    skip_input: {
      classification: 'text',
      component: 'skipInput',
      slotProps: {
        label: ['skipType', 'canSkip']
      }
    },
    tag_input: {
      classification: 'text',
      component: 'TagInput',
      slotProps: {
        errors: ['errorText']
      }
    }
  }
}
