<template>
  <div :key="refreshKey" class="h-100">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
import { useStore } from '@/app/app-store'
import '@/assets/scss/theme.scss'
import { computed } from '@vue/composition-api'

export default {
  name: 'base-layout',
  setup() {
    const { $store } = useStore()
    const refreshKey = computed(() => $store.getters['dashboard/refreshKey'])

    return {
      refreshKey
    }
  }
}
</script>
