import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'

import { formulateConfig } from './formulate.config'

export function registerFormulatePlugin() {
  Vue.use(VueFormulate, formulateConfig)
  Vue.use(FormulateVSelectPlugin)
}
