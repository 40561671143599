import Vue from 'vue'
import vSelect from 'vue-select'
import PageHeader from '@/app/dashboard/components/shared/page-header.vue'
import {
  AjaxMultiSelect,
  AjaxSelect,
  AllocationInput,
  CkEditorInput,
  ClassificationInput,
  CustomBase64FileInput,
  CustomDatasetInput,
  CustomDateInput,
  CustomFileInput,
  CustomHelpInput,
  CustomLabelInput,
  CustomNormalInput,
  CustomOptionSelect,
  EmailVerificationInput,
  IamportInput,
  JsonEditorInput,
  JsonSchemaInput,
  SkipInput,
  TagInput
} from '@/app/dashboard/components/shared/form/index.js'

export function registerComponents() {
  Vue.component('v-select', vSelect)
  Vue.component('page-header', PageHeader)
  Vue.component('CustomOptionSelect', CustomOptionSelect)
  Vue.component('AjaxSelect', AjaxSelect)
  Vue.component('AjaxMultiSelect', AjaxMultiSelect)
  Vue.component('ClassificationInput', ClassificationInput)
  Vue.component('JsonSchemaInput', JsonSchemaInput)
  Vue.component('TagInput', TagInput)
  Vue.component('CkEditorInput', CkEditorInput)
  Vue.component('JsonEditorInput', JsonEditorInput)
  Vue.component('EmailVerificationInput', EmailVerificationInput)
  Vue.component('CustomDatasetInput', CustomDatasetInput)
  Vue.component('CustomLabelInput', CustomLabelInput)
  Vue.component('CustomNormalInput', CustomNormalInput)
  Vue.component('CustomHelpInput', CustomHelpInput)
  Vue.component('IamportInput', IamportInput)
  Vue.component('CustomFileInput', CustomFileInput)
  Vue.component('SkipInput', SkipInput)
  Vue.component('CustomBase64FileInput', CustomBase64FileInput)
  Vue.component('AllocationInput', AllocationInput)
  Vue.component('CustomDateInput', CustomDateInput)
}
