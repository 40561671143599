import { AuthToken } from '@/app/shared/auth/auth-token'
import { TenantToken } from '@/app/shared/auth/tenant-token'

export class Auth {
  static isLoggedIn() {
    return !!AuthToken.get()
  }

  static isTenantIn() {
    return !!TenantToken.get()
  }
}
